
<template>
    <div
        class="
        lni-c-site-footer__container--functional
        lni-u-pt3
        lni-u-pb4
        lni-u-ph3
        lni-o-color-context--inverse
        lni-u-line-height--tight
    "
    >
        <div class="lni-c-site-footer--functional lni-u-mhauto">
            <div class="lni-u-flex-wrap lni-u-flex lni-u-justify-between lni-u-items-start">
                <div class="lni-c-site-footer__column lni-u-mr2">
                    <h2 class="lni-u-text--all-caps">
                        {{ location.heading }}
                    </h2>
                    <address class="lni-u-mt2">
                        {{ location.address }}<br>
                        {{ location.address2 }}
                    </address>
                    <a
                        class="lni-u-inline-block lni-u-mt2"
                        :href="office.link"
                    >{{ office.label }}</a>
                    <h2 class="lni-u-text--all-caps lni-u-mt3">
                        {{ phone.heading }}
                    </h2>
                    <div class="lni-c-footer__phone lni-u-mt2">
                        <div>
                            {{ phone.switchboardObj.label }}
                            <a
                                :href="`tel:` + phone.switchboardObj.number"
                            >
                                {{ phone.switchboardObj.number }}</a>
                        </div>
                        <div>
                            {{ phone.ttyObj.label }}
                            <a
                                :href="`tel:+1` + phone.ttyObj.number"
                            >
                                {{ phone.ttyObj.number }}</a>
                        </div>
                        <div class="lni-u-mv2 lni-u-type--xxs lni-u-line-height--tight">
                            {{ phone.ttyNotice }}
                        </div>
                    </div>
                </div>
                <div class="lni-c-site-footer__column">
                    <h2 class="lni-u-text--all-caps">
                        {{ accountability.heading }}
                    </h2>
                    <ul
                        class="
                            lni-u-list-reset
                            lni-o-color-context--inverse
                            lni-u-flex
                            lni-u-flex-column
                            lni-u-mb3
                            lni-u-mr2
                        "
                    >
                        <li
                            v-for="link in accountability.links"
                            :key="link.label"
                            class="lni-u-mt1"
                        >
                            <a
                                class="lni-c-site-footer__link"
                                :href="link.route"
                            >{{ link.label }}
                            </a>
                        </li>
                    </ul>
                    <h2 class="lni-u-text--all-caps">
                        {{ involved.heading }}
                    </h2>
                    <ul
                        class="
                            lni-u-list-reset
                            lni-o-color-context--inverse
                            lni-u-flex
                            lni-u-flex-column
                            lni-u-mb3
                            lni-u-mr2
                        "
                    >
                        <li
                            v-for="link in involved.links"
                            :key="link.label"
                            class="lni-u-mt1"
                        >
                            <a
                                class="lni-c-site-footer__link"
                                :href="link.route"
                            >{{ link.label }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="lni-c-site-footer__column">
                    <div>
                        <h2 class="lni-u-text--all-caps lni-u-mb2">
                            {{ emailSignUp.heading }}
                        </h2>
                        <a :href="emailSignUp.link">{{ emailSignUp.label }}</a>
                    </div>
                    <h2 class="lni-u-text--all-caps lni-u-mt3">
                        {{ social.followLabel }}
                    </h2>
                    <div class="lni-u-flex lni-u-flex-wrap lni-u-mt2">
                        <a
                            v-for="(sLink) in social.links"
                            :key="sLink.name"
                            :href="sLink.route"
                            :aria-label="sLink.alt"
                            class="lni-c-site-footer__social lni-u-text--center lni-u-flex lni-u-justify-center lni-u-items-center lni-u-mr2"
                        >
                            <lni-svg-icon
                                v-if="sLink.type == 'instagram'"
                                id="svg-icon-footer-instagram"
                                :key="sLink.name"
                                class="lni-u-full-width lni-u-full-height"
                                :href="sLink.route"
                                :fill="null"
                                :stroke="null"
                                width="35"
                                height="35"
                                :aria-label="sLink.alt"
                            >
                                <instagram />
                            </lni-svg-icon>
                            <lni-svg-icon
                                v-else-if="sLink.type == 'twitter'"
                                id="svg-icon-footer-twitter"
                                :key="sLink.name"
                                :href="sLink.route"
                                :fill="null"
                                :stroke="null"
                                width="24"
                                height="24"
                                viewBox="0 0 1200 1227"
                                :aria-label="sLink.alt"
                            >
                                <twitter-x />
                            </lni-svg-icon>
                            <span
                                v-else
                                :class="'lnicon--' + sLink.type"
                                class="lni-c-site-footer__social-logo"
                                aria-hidden="true"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LniSvgIcon from '@gov.wa.lni/component.lni-svg-icon/lni-svg-icon.component.vue';
import instagram from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-functional-footer/icons/instagram.vue';
import twitterX from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-functional-footer/icons/twitter-x.vue';

export default {
    name: 'LniFunctionalFooter',
    components: {
        instagram,
        twitterX,
        LniSvgIcon,
    },
}; </script>