<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width ? width : getSize(id)"
        :height="height ? height : getSize(id)"
        :viewBox="viewBox"
        :aria-labelledby="id"
        role="presentation"
        aria-hidden="true">
        <title :id="id">
            {{ id }} icon
        </title>
        <g
            :class="[
                fill ? `icon-fill--${getFill(fill, id)}` : '',
                stroke ? `icon-stroke--${getStroke(stroke, id)}`: '']">
            <slot></slot>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'lni-svg-icon',
    props: {
        xs: Boolean,
        sm: Boolean,
        md: Boolean,
        lg: Boolean,
        xl: Boolean,
        viewBox: {
            type: String,
            default: '0 0 24 24',
        },
    },
    data() {
        return {
            sizeObj: {
                xs: 16,
                sm: 24,
                md: 32,
                lg: 40,
                xl: 48,
            },
            colorArray: [
                'white',
                'alto',
                'mid-gray',
                'deep-sapphire',
                'bahama-blue',
                'science-blue',
                'monza',
                'gamboge',
                'japanese-laurel',
                'transparent',
            ],
        };
    },
    methods: {
        getSize(id) {
            let sizeInPixels = this.sizeObj.sm;
            Object.entries(this.sizeObj).forEach(([key, val]) => {
                sizeInPixels = this[key] ? val : sizeInPixels;
            });

            if (sizeInPixels === undefined) {
                console.warn(`Size for ${id} Icon does not exist please omit or enter one of the following: xs, sm, md, lg, xl`);
            }
            return sizeInPixels;
        },
        getFill(color = 'mid-gray', id) {
            let isOption = this.colorArray.includes(color);

            if (isOption) {
                return color;
            }
            console.warn(`Color for ${id} Icon does not exist please omit or enter a valid one-lni color`);
            return 'mid-gray';
        },
        getStroke(color = 'white', id) {
            let isOption = this.colorArray.includes(color);

            if (isOption) {
                return color;
            }
            console.warn(`Color for ${id} Icon does not exist please omit or enter a valid one-lni color`);
            return 'mid-gray';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@gov.wa.lni/framework.one-lni/source/styles/settings';

@each $key in map-keys($palette) {
    .icon-fill--#{$key} {
        fill: map-get($palette, $key);
    }
};

@each $key in map-keys($palette) {
    .icon-stroke--#{$key} {
        stroke: map-get($palette, $key);
    }
};

.icon-fill--transparent {
    fill: transparent;
}
</style>