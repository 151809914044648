/* eslint-disable comma-spacing */
/* eslint-disable quote-props */
/* eslint-disable key-spacing */
/* eslint-disable max-len */
/* eslint-disable max-params */

import EnvironmentMap from '@gov.wa.lni/framework.one-lni.core/source/environment/EnvironmentMap';
import E, {
    TYPE_DEVELOPMENT, TYPE_INTERMEDIATE,TYPE_PRODUCTION,
} from '@gov.wa.lni/framework.one-lni.core/source/environment/Environment';

const map = new EnvironmentMap();

/*
Note:
Currently we check the name and if it contains either Authenticated or Anonymous then analytics is
loaded.

TODO: [Aaron] Improve this so that analytics is explicitly opt in.
*/


map.add(new E('localhost'                        , 'Local Development'             , TYPE_DEVELOPMENT , true  , 'https://dev-secure.lni.wa.gov'           , 'https://dev-public.lni.wa.gov/application/api'           , 'https://dev-public.lni.wa.gov/api/vtl'           , 'http://protodev.lni.wa.gov/api'    , true ));
map.add(new E('docs.dev.wads.wa.gov'             , 'Documentation    '             , TYPE_DEVELOPMENT , false , 'https://dev-secure.lni.wa.gov'           , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://protodev.lni.wa.gov/api'   , false));
map.add(new E('dev-secure.lni.wa.gov'            , 'Authenticated Integration'     , TYPE_DEVELOPMENT , true  , 'https://dev-secure.lni.wa.gov'           , 'https://dev-public.lni.wa.gov/application/api'           , 'https://dev-public.lni.wa.gov/api/vtl'           , 'https://protodev.lni.wa.gov/api'   , false));
map.add(new E('test-secure.lni.wa.gov'           , 'Authenticated PreProduction'   , TYPE_INTERMEDIATE, true  , 'https://test-secure.lni.wa.gov'          , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('secure.lni.wa.gov'                , 'Authenticated Production'      , TYPE_PRODUCTION  , false , 'https://secure.lni.wa.gov'               , 'https://lni.wa.gov/application/api'                      , 'https://lni.wa.gov/api/vtl'                      , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('test-fortress.wa.gov'             , 'Anonymous PreProduction'       , TYPE_INTERMEDIATE, true  , 'https://test-secure.lni.wa.gov'          , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('fortress.wa.gov'                  , 'Anonymous Production'          , TYPE_PRODUCTION  , false , 'https://secure.lni.wa.gov'               , 'https://lni.wa.gov/application/api'                      , 'https://lni.wa.gov/api/vtl'                      , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('dev-public.lni.wa.gov'            , 'Public Development'            , TYPE_DEVELOPMENT , true  , 'https://dev-public.lni.wa.gov'           , 'https://dev-public.lni.wa.gov/application/api'           , 'https://dev-public.lni.wa.gov/api/vtl'           , 'https://protodev.lni.wa.gov/api'   , true ));
map.add(new E('lni-dev-upgrade.dotcms.cloud'     , 'Public Development Upgrade'    , TYPE_DEVELOPMENT , true  , 'https://lni-dev-upgrade.dotcms.cloud'    , 'https://lni-dev-upgrade.dotcms.cloud/application/api'    , 'https://lni-dev-upgrade.dotcms.cloud/api/vtl'    , 'https://protodev.lni.wa.gov/api'   , true ));
map.add(new E('demo-public.lni.wa.gov'           , 'Public Staging'                , TYPE_INTERMEDIATE, true  , 'https://demo-public.lni.wa.gov'          , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://protodev.lni.wa.gov/api'   , true ));
map.add(new E('lni-demo-upgrade.dotcms.cloud'    , 'Public Staging Upgrade'        , TYPE_INTERMEDIATE, true  , 'https://lni-demo-upgrade.dotcms.cloud'   , 'https://lni-demo-upgrade.dotcms.cloud/application/api'   , 'https://lni-demo-upgrade.dotcms.cloud/api/vtl'   , 'https://protodev.lni.wa.gov/api'   , true ));
map.add(new E('lni.wa.gov'                       , 'Public Production'             , TYPE_PRODUCTION  , false , 'https://lni.wa.gov'                      , 'https://lni.wa.gov/application/api'                      , 'https://lni.wa.gov/api/vtl'                      , 'https://apps-public.lni.wa.gov/api', true ));
map.add(new E('lni-prod-upgrade.dotcms.cloud'    , 'Public Production Upgrade'     , TYPE_PRODUCTION  , false , 'https://lni-prod-upgrade.dotcms.cloud'   , 'https://lni-prod-upgrade.dotcms.cloud/application/api'   , 'https://lni-prod-upgrade.dotcms.cloud/api/vtl'   , 'https://apps-public.lni.wa.gov/api', true ));
map.add(new E('www.lni.wa.gov'                   , 'Public Production'             , TYPE_PRODUCTION  , false , 'https://www.lni.wa.gov'                  , 'https://www.lni.wa.gov/application/api'                  , 'https://www.lni.wa.gov/api/vtl'                  , 'https://apps-public.lni.wa.gov/api', true ));
map.add(new E('dev.wads.wa.gov'                  , 'Internal Development'          , TYPE_DEVELOPMENT , true  , 'https://dev-secure.lni.wa.gov'           , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://protodev.lni.wa.gov/api'   , false));
map.add(new E('unit-test.wads.wa.gov'            , 'Internal Integration'          , TYPE_DEVELOPMENT , true  , 'https://dev-secure.lni.wa.gov'           , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://protodev.lni.wa.gov/api'   , false));
map.add(new E('test-inside.lni.wa.gov'           , 'Internal PreProduction'        , TYPE_INTERMEDIATE, true  , 'https://test-secure.lni.wa.gov'          , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('apps-inside.lni.wa.gov'           , 'Internal Production'           , TYPE_PRODUCTION  , true  , 'https://secure.lni.wa.gov'               , 'https://lni.wa.gov/application/api'                      , 'https://lni.wa.gov/api/vtl'                      , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('apps.lni.wa.gov'                  , 'Internal Production 3'         , TYPE_PRODUCTION  , true  , 'https://secure.lni.wa.gov'               , 'https://lni.wa.gov/application/api'                      , 'https://lni.wa.gov/api/vtl'                      , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('test-internet.lni.wa.gov'         , 'Alt Internal PreProduction'    , TYPE_INTERMEDIATE, true  , 'https://test-secure.lni.wa.gov'          , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('dev2-secure.lni.wa.gov'           , 'Authenticated Integration'     , TYPE_DEVELOPMENT , true  , 'https://dev-secure.lni.wa.gov'           , 'https://dev-public.lni.wa.gov/application/api'           , 'https://dev-public.lni.wa.gov/api/vtl'           , 'https://protodev.lni.wa.gov/api'   , false));
map.add(new E('dev-ui-docs.lni.wa.gov'           , 'UI Docs Development'           , TYPE_DEVELOPMENT , false , 'https://dev-ui-docs.lni.wa.gov'          , 'https://dev-ui-docs.lni.wa.gov/application/api'          , 'https://dev-ui-docs.lni.wa.gov/api/vtl'          , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('demo-ui-docs.lni.wa.gov'          , 'UI Docs Staging'               , TYPE_INTERMEDIATE, false , 'https://demo-ui-docs.lni.wa.gov'         , 'https://demo-ui-docs.lni.wa.gov/application/api'         , 'https://demo-ui-docs.lni.wa.gov/api/vtl'         , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('ui-docs.lni.wa.gov'               , 'UI Docs Production'            , TYPE_PRODUCTION  , false , 'https://ui-docs.lni.wa.gov'              , 'https://ui-docs.lni.wa.gov/application/api'              , 'https://ui-docs.lni.wa.gov/api/vtl'              , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('lnidaptumcom91'                   , 'Internal Integration 2'        , TYPE_DEVELOPMENT , true  , 'https://dev-secure.lni.wa.gov'           , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://protodev.lni.wa.gov/api'   , false));
map.add(new E('lnixapolycom9pr'                  , 'Internal PreProduction 2'      , TYPE_INTERMEDIATE, true  , 'https://test-secure.lni.wa.gov'          , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('lnixapolycom9'                    , 'Internal Production 2'         , TYPE_PRODUCTION  , true  , 'https://secure.lni.wa.gov'               , 'https://lni.wa.gov/application/api'                      , 'https://lni.wa.gov/api/vtl'                      , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('sowa.stage.sumtotal.host'         , 'SumTotal PreProduction'        , TYPE_INTERMEDIATE, true  , 'https://test-secure.lni.wa.gov'          , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('sowa.sumtotal.host'               , 'SumTotal Production'           , TYPE_PRODUCTION  , false , 'https://secure.lni.wa.gov'               , 'https://lni.wa.gov/application/api'                      , 'https://lni.wa.gov/api/vtl'                      , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('test-drivers-complaint.lni.wa.gov', 'TNC Staging'                   , TYPE_INTERMEDIATE, true  , 'https://test-secure.lni.wa.gov'          , 'https://demo-public.lni.wa.gov/application/api'          , 'https://demo-public.lni.wa.gov/api/vtl'          , 'https://apps-public.lni.wa.gov/api', false));
map.add(new E('drivers-complaint.lni.wa.gov'     , 'TNC Production'                , TYPE_PRODUCTION  , false , 'https://secure.lni.wa.gov'               , 'https://lni.wa.gov/application/api'                      , 'https://lni.wa.gov/api/vtl'                      , 'https://apps-public.lni.wa.gov/api', false));

export default map;