<template>
    <nav
        v-click-outside="toggleMobileMenu"
        class="lni-c-mega-menu"
        :class="{ '--menu-open': menuToggleOpen }"
        @keydown.esc.exact="toggleMobileMenu"
    >
        <button
            v-show="!isWide"
            ref="mobileOpenButton"
            class="lni-c-mega-menu__main-toggle lni-u-mlauto"
            aria-label="Main Menu"
            aria-controls="lni-mega-menu-secure"
            :aria-expanded="menuToggleOpen"
            @click="toggleMobileMenu"
        >
            <div
                v-show="!isWide"
                aria-hidden="true"
                class="lnicon--menu lni-u-type--xl"
            />
            <span class="lni-u-visually-hidden --focusable">Menu</span>
        </button>
        <div class="lni-c-mega-menu__outer-wrapper lni-c-mega-menu-secure__background">
            <transition name="slide-from-right">
                <div
                    v-show="menuToggleOpen || isWide"
                    class="lni-c-mega-menu__inner-wrapper lni-c-mega-menu-secure__background"
                >
                    <div
                        id="lni-mega-menu"
                        class="lni-c-mega-menu__level --level1"
                    >
                        <lni-mega-menu-heading
                            v-show="!isWide"
                            ref="level1Header"
                            :level="1"
                            :secure="true"
                            @click="toggleMobileMenu"
                        >
                            <label class="lni-u-mt2">
                                {{ userName }}
                            </label>
                        </lni-mega-menu-heading>
                        <ul
                            class="lni-c-mega-menu__list --level1"
                            :class="!isWide?'lni-u-ma2 lni-u-pa2':''"
                        >
                            <li class="lni-c-mega-menu__tab --mobile">
                                <div class="lni-u-full-height">
                                    <a
                                        id="profile-link"
                                        class="lni-c-mega-menu__item lni-c-mega-menu__toggle --level1 lni-u-flex lni-u-items-center lni-c-mega-menu-secure__background"
                                        href="/Default.aspx#profile"
                                    >
                                        My Profile
                                    </a>
                                </div>
                            </li>
                            <li
                                v-for="entity in content"
                                :key="entity.entity_id"
                                class="lni-c-mega-menu__tab --mobile"
                            >
                                <div class="lni-u-full-height">
                                    <button
                                        :id="makeRef(entity.entity_id)"
                                        :ref="entity.entity_id"
                                        class="lni-c-mega-menu__item lni-c-mega-menu__toggle --level1 lni-u-flex lni-u-items-center lni-c-mega-menu-secure__background"
                                        :aria-controls="entity.entity_id"
                                        @click="entityClick"
                                    >
                                        {{ entity.entity_name }}
                                    </button>
                                </div>
                            </li>
                            <li>
                                <a
                                    id="get-more-access-link"
                                    class="lni-c-mega-menu__item lni-c-mega-menu__toggle --level1 lni-u-flex lni-u-items-center lni-c-mega-menu-secure__background"
                                    href="/Default.aspx#registration"
                                >
                                    Get More Access +
                                </a>
                            </li>
                            <li class="lni-c-mega-menu__tab --mobile">
                                <div class="lni-u-full-height">
                                    <button
                                        class="lni-c-mega-menu__item lni-c-mega-menu__toggle --level1 lni-u-flex lni-u-items-center lni-c-mega-menu-secure__background"
                                        @click="logOutButtonClick"
                                        @keydown.tab.exact="sendFocusToStart($event)"
                                    >
                                        Log Out
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </transition>
        </div>
    </nav>
</template>

<script>

import LniMegaMenuHeading from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-mega-menu/lni-mega-menu-heading.vue';
import clickOutside from '@gov.wa.lni/framework.one-lni.directives/source/clickOutside.js';

import {
    onEntityClick,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/onEntityClick.js';

const body = document.querySelector('body');

/**
 *  The lni-mega-menu component.
 *  @module components/lni-mega-menu-secure
 */

export default {
    name: 'LniMegaMenuSecure',
    components: {
        LniMegaMenuHeading,
    },
    directives: {
        clickOutside,
    },
    props: {
        id: String,
        classString: String,
        content: {
            type: Object,
        },
        userName: String,
    },
    data() {
        return {
            isWide: true,
            menuToggleOpen: false,
        };
    },
    mounted() {
        const isItWide = window.matchMedia('(min-width: 715px)');
        const setWide = () => {
            this.isWide = isItWide.matches;
        };
        isItWide.addListener(setWide);
        this.$nextTick(() => setWide(isItWide));
    },
    methods: {
        toggleMobileMenu() {
            if ( this.menuToggleOpen ) {
                //close
                this.menuToggleOpen = false;
                body.classList.remove('--scroll-lock');
                this.$nextTick(() => this.$refs.mobileOpenButton.focus());
            } else {
                //open
                this.menuToggleOpen = true;
                body.classList.add('--scroll-lock');
                this.$nextTick(() => this.$refs.level1Header.$refs.mobileBackButton.focus());
            }
        },
        logOutButtonClick(e) {
            e.preventDefault();
            const seapUrl = window.location.origin + '/pkmslogout';
            const iframe = document.createElement('iframe');
            const sawLogoutUrl = this.getLogoutUrl();
            iframe.style.display = 'none';
            iframe.src = sawLogoutUrl;
            iframe.sandbox = 'allow-same-origin';
            iframe.id = 'logOutIFrame';
            document.body.appendChild(iframe);

            document.getElementById('logOutIFrame').addEventListener('load', () => {
                window.location = seapUrl;
            });
        },
        getLogoutUrl: function getLogoutUrl() {
            const baseUrl = this.$oneLni.environment.current.baseUrl;
            let sawUrl = 'secureaccess.wa.gov';
            if (baseUrl.indexOf('test-') > -1) {
                sawUrl = `https://test-${sawUrl}/myFortress`;
            } else {
                sawUrl = `https://${sawUrl}/myAccess`;
            }
            sawUrl += '/saw/logout.do';
            return sawUrl;
        },
        entityClick(event) {
            onEntityClick(event, this.content);
            this.toggleMobileMenu();
        },
        sendFocusToStart(event) {
            event.preventDefault();
            this.$refs.level1Header.$refs.mobileBackButton.focus();
        },
        makeRef(item) {
            return item;
        },
    },
}; </script>