/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import Environment, { TYPE_PRODUCTION } from '@gov.wa.lni/framework.one-lni.core/source/environment/Environment';
import script from '@gov.wa.lni/framework.one-lni.core/source/lib/script';

export default class EnvironmentMap {
    private _default: Environment;
    private _map: {
        [key: string]: Environment;
    };

    /**
     * Get the hostname from which one-lni has been loaded. If the host name can't be reached, it
     * gets the hostname from which one-lni was served.
     * @returns The hostname where one-lni has been loaded
     */
    public static getCurrentHost(): string {
        let result = window.location.hostname;

        // hostname is missing when loading from an iframe that we haven't set the src attribute on.
        if (!result) {
            let oneLniScript = script('oneLni.js');

            if (!oneLniScript) {
                // This would happen when running in the sandbox environment
                oneLniScript = script('sandbox.js');
            }

            const baseUrl: string = oneLniScript!.root.split(/\/?one-lni\/v3/i)[0]!;
            result = baseUrl.replace('https://', '');
        }

        return result;
    }

    constructor() {
        this._map = {};
        this._default = new Environment(
            EnvironmentMap.getCurrentHost(),
            'Unknown',
            TYPE_PRODUCTION,
            true,
            'https://lni.wa.gov',
            'https://lni.wa.gov/application/api',
            'https://lni.wa.gov/api/vtl',
            'https://apps-public.lni.wa.gov/api',
            false);
    }

    /**
     * Gets the default environment that can be used in case the current one is not recognized.
     */
    get default() {
        return this._default;
    }

    /**
     * Adds a new environment to the map. The host name must be unique.
     * @param environment The environment to add.
     */
    public add(environment: Environment) {
        if (this._map[environment.host]) {
            throw new Error(`The environment ${environment.host} has already been added`);
        }

        this._map[environment.host] = environment;
    }

    /**
     * Detects which environment has loaded this instances of one-lni.
     * @returns A matching environment or the default one.
     */
    detect(): Environment {
        const key = EnvironmentMap.getCurrentHost();

        if (this._map[key]) {
            return this._map[key]!;
        }

        // take off the subdomain and check if it maps to one of our environments
        // e.g. ohms.unit-test.wads.wa.gov -> unit-test.wads.wa.gov
        if (key.indexOf('.') !== -1) {
            const keyMinusSubDomain = key.substring(key.indexOf('.') + 1);
            if (this._map[keyMinusSubDomain]) {
                return this._map[keyMinusSubDomain]!;
            }
        }

        return this.default;
    }
}