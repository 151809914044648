import {
    getCurrentLocation,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/getCurrentLocation.js';

export const getEntities = (callback, oneLni) => {
    const currentEnvironment = getCurrentLocation(oneLni);
    let entitiesURL = `${currentEnvironment}/ewn/v4/GatewayProxy/UiProxy.aspx/UiGatewayRestMethod`;
    const sendData = {
        ServiceName: 'EwnMenuService',
        MethodName: 'GetEntities',
        Params: [window.location.pathname],
    };
    fetch(entitiesURL, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(sendData),
    }).then(response => response.json())
        .then(data => {
            callback(data);
        });
};