/* eslint-disable no-underscore-dangle */

//NOTE: The build will swap this to './flags.prod' for release builds.
import flags from '@gov.wa.lni/framework.one-lni.core/source/environment/flags.dev.js';

export const TYPE_DEVELOPMENT: string = 'development';
export const TYPE_INTERMEDIATE: string = 'intermediate';
export const TYPE_PRODUCTION: string = 'production';

const isRelease: boolean = flags.isRelease;
const isUnitTestEnvironment: boolean = typeof process !== 'undefined';

/**
 * Represents an environment configuration.
 * This is based on where the host where framework might be loaded.
 * Any time a new host is loading one-lni (IE a SaaS product) a new environment record needs to be
 * created for it.
 */
export default class Environment {
    private _host: string;
    private _name: string;
    private _type: string;
    private _display: boolean;
    private _baseUrl: string;
    private _apiLocation: string;
    private _apiLocation2: string;
    private _publicApiLocation: string;
    private _isMultilingual: boolean;

    private _isRelease: boolean;
    private _isUnitTestEnvironment: boolean;

    // eslint-disable-next-line max-params
    constructor(
        host: string,
        name: string,
        type: string,
        display: boolean,
        baseUrl: string,
        apiLocation: string,
        apiLocation2: string,
        publicApiLocation: string,
        isMultilingual: boolean,
    ) {

        this._host = host;
        this._name = name;
        this._type = type;
        this._display = display;
        this._baseUrl = baseUrl;
        this._apiLocation = apiLocation;
        this._apiLocation2 = apiLocation2;
        this._publicApiLocation = publicApiLocation;
        this._isMultilingual = isMultilingual;

        this._isRelease = isRelease;
        this._isUnitTestEnvironment = isUnitTestEnvironment;
    }

    /**
     * @returns The hostname portion of the url from where one-lni is being loaded
     */
    get host(): string {
        return this._host;
    }

    /**
     * The name of the environment.
     */
    get name(): string {
        return this._name;
    }

    get type(): string {
        return this._type;
    }

    get display(): boolean {
        return this._display;
    }

    get baseUrl(): string {
        return this._baseUrl;
    }

    get apiLocation(): string {
        return this._apiLocation;
    }

    get apiLocation2(): string {
        return this._apiLocation2;
    }

    get publicApiLocation(): string {
        return this._publicApiLocation;
    }

    get isMultilingual(): boolean {
        return this._isMultilingual;
    }

    /**
     * True if this is a release build.
     */
    get isRelease(): boolean {
        return this._isRelease;
    }

    /**
     * True if the environment is running in node.
     */
    get isUnitTestEnvironment(): boolean {
        return this._isUnitTestEnvironment;
    }

    /**
     * Returns true if the environment name contains "Anonymous" or "Authenticated"
     */
    get supportsAnalytics(): boolean {
        const isExternalAppsEcosystem = this.name.includes('Authenticated')
            || this.name.includes('Anonymous');

        const isUpper = (this.type === 'intermediate') || (this.type === 'production');

        return isUpper && isExternalAppsEcosystem;
    }
}